import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";

export const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK, 
    options: {
      infuraId: "6de59dc14a914133b494e6208a6b914f"
    }
  },
  walletconnect: {
    package: WalletConnect, 
    options: {
      infuraId: "6de59dc14a914133b494e6208a6b914f"
    }
  } 
};